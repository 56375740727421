import React from "react"
import ProductBanner from '../banners/product-banner'
import NotFoundBanner from "../banners/notfound-banner";
import GraphicBanner from "../banners/graphic-banner";
import CampaignBanner from "../banners/campaign-banner";

export default function PageBanner({ component }) {
  if (component !== null) {
    let typename = component.__typename || component.id?.split("-")[0];
    switch (typename) {
      case 'DatoCmsProductBannerModule':
        return <ProductBanner data={component} />
      case 'DatoCmsNotfoundBanner':
        return <NotFoundBanner data={component} />
      case 'DatoCmsGraphicBanner':
        return <GraphicBanner data={component} />
      case 'DatoCmsCampaignBanner':
        return <CampaignBanner data={component} />

      default:
        return null
    }
  } else {
    return null
  }
}
